import React from "react";
import Modal from "react-modal";
import NewsletterSignup from "../NewsletterSignup/NewsletterSignup";
import "./NewsletterModal.css";

Modal.setAppElement("#root");

const NewsletterModal = ({ closeModal }) => (
  <>
    <Modal
      className="modal-newsletter-signup-container"
      onRequestClose={closeModal}
      isOpen
    >
      <p className="modal__close-button" onClick={closeModal}>
        X
      </p>
      <NewsletterSignup
        className="modal__newsletter-signup"
        onSuccess={closeModal}
      />
    </Modal>
  </>
);

export default NewsletterModal;
