import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import NewsletterModal from "./components/NewsletterModal/NewsletterModal";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./App.css";

const App = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shouldShowModal = localStorage.getItem("should-show-modal") || true;
  const location = useLocation();
  const shouldRenderComponent = location.pathname !== "/mentoria-imagem-topo";
  let timeout = useRef(null);

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.setItem("should-show-modal", false);
  };

  useEffect(() => {
    if (shouldShowModal === true) {
      timeout.current = setTimeout(() => setIsModalOpen(true), 20000);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shouldRenderComponent ? (
    <>
      <Header />
      {children}
      <Footer />
      {isModalOpen && shouldShowModal ? (
        <NewsletterModal closeModal={closeModal} />
      ) : null}
    </>
  ) : null;
};

export default App;
