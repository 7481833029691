import React, { lazy, Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";
import ReactDOM from "react-dom/client";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const Home = lazy(() => import("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const Contacts = lazy(() => import("./pages/Contacts/Contacts"));
const Services = lazy(() => import("./pages/Services/Services"));
const AuthenticImageMentoring = lazy(() =>
  import("./pages/Services/AuthenticImageMentoring")
);
const FindYourStyle = lazy(() => import("./pages/Services/FindYourStyle"));
const LookAssembly = lazy(() => import("./pages/Services/LookAssembly"));
const Styling = lazy(() => import("./pages/Services/Styling"));
const ComercialStyling = lazy(() =>
  import("./pages/Services/ComercialStyling")
);
const TravelBagPlanning = lazy(() =>
  import("./pages/Services/TravelBagPlanning")
);
const PersonalColorAnalysis = lazy(() =>
  import("./pages/Services/PersonalColorAnalysis")
);
const WorkshopPro = lazy(() => import("./pages/Services/WorkshopPro"));
const GiftVouchers = lazy(() => import("./pages/Services/GiftVouchers"));
const TermsConditions = lazy(() => import("./pages/legal/terms-conditions"));
const PrivacyPolicy = lazy(() => import("./pages/legal/privacy-policy"));
const MentoriaImagemTopo = lazy(() =>
  import("./pages/mentoria-imagem-topo/MentoriaImagemTopo")
);
const LinkTree = lazy(() => import("./pages/link-tree/LinkTree"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<LoadingAnimation />}>
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <App>
                <Home />
              </App>
            }
          />
          <Route
            exact
            path="/about"
            element={
              <App>
                <About />
              </App>
            }
          />
          <Route
            exact
            path="/contacts"
            element={
              <App>
                <Contacts />
              </App>
            }
          />
          <Route
            exact
            path="/services"
            element={
              <App>
                <Services />
              </App>
            }
          />
          <Route
            exact
            path="/services/authentic-image-mentoring"
            element={
              <App>
                <AuthenticImageMentoring />
              </App>
            }
          />
          <Route
            exact
            path="/services/find-your-style"
            element={
              <App>
                <FindYourStyle />
              </App>
            }
          />
          <Route
            exact
            path="/services/look-assembly"
            element={
              <App>
                <LookAssembly />
              </App>
            }
          />
          <Route
            exact
            path="/services/styling"
            element={
              <App>
                <Styling />
              </App>
            }
          />
          <Route
            exact
            path="/services/styling-comercial"
            element={
              <App>
                <ComercialStyling />
              </App>
            }
          />
          <Route
            exact
            path="/services/travel-bag-planning"
            element={
              <App>
                <TravelBagPlanning />
              </App>
            }
          />
          <Route
            exact
            path="/services/personal-color-analysis"
            element={
              <App>
                <PersonalColorAnalysis />
              </App>
            }
          />
          <Route
            exact
            path="/services/workshop-pro"
            element={
              <App>
                <WorkshopPro />
              </App>
            }
          />
          <Route
            exact
            path="/services/gift-vouchers"
            element={
              <App>
                <GiftVouchers />
              </App>
            }
          />
          <Route
            exact
            path="/legal/terms-conditions"
            element={
              <App>
                <TermsConditions />
              </App>
            }
          />
          <Route
            exact
            path="/legal/privacy-policy"
            element={
              <App>
                <PrivacyPolicy />
              </App>
            }
          />
          <Route
            exact
            path="/mentoria-imagem-topo"
            element={<MentoriaImagemTopo />}
          />
          <Route exact path="/links-uteis" element={<LinkTree />} />
          <Route
            path="*"
            element={
              <App>
                <Home />
              </App>
            }
          />
        </Routes>
      </Suspense>
      <WhatsAppButton />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
