import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import BrandLettering from "../../assets/images/brand-lettering.png";
import "./Header.css";

const Header = () => {
  const location = useLocation();
  const [isHomepage, setIsHomepage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    setIsHomepage(location.pathname === "/");

    const handleScroll = () => {
      const scrollThreshold = 300;
      if (window.scrollY >= scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return (
    <div className="header">
      <div
        className={`header__logo-navbar-wrapper ${
          isScrolled ? "header__logo-navbar-wrapper--scrolled" : ""
        } ${isHomepage ? "header__logo-navbar-wrapper--homepage-normal" : ""} ${
          isHomepage && isScrolled
            ? "header__logo-navbar-wrapper--homepage-scrolled"
            : ""
        }`}
      >
        <div className="header__content-wrapper">
          <div className="brand-lettering__wrapper center">
            <Link className="brand-lettering__content" to="/">
              <picture>
                <img
                  src={BrandLettering}
                  alt="Cristiana Silva Brand Lettering"
                  width="300"
                  fetchpriority="high"
                />
              </picture>
            </Link>
          </div>
          <NavBar />
        </div>
      </div>
    </div>
  );
};

export default Header;
