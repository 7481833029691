import WhatsAppIcon from "../../assets/images/whatsapp_icon.svg";
import "./WhatsAppButton.css";

const WhatsAppButton = () => (
  <a
    className="whatsapp-button"
    aria-label="Fale comigo no Whatsapp"
    href="https://wa.me/351912499133"
    target="blank"
  >
    <img
      className="whatsapp-button__icon"
      alt="Fale comigo no Whatsapp"
      src={WhatsAppIcon}
    />
  </a>
);

export default WhatsAppButton;
