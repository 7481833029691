import Spinner from "../Spinner/Spinner";
import "./LoadingAnimation.css";

const LoadingAnimation = () => (
  <div className="loading-animation__container">
    <div className="loading-animation__wrapper">
      <Spinner />
    </div>
  </div>
);

export default LoadingAnimation;
